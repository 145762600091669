.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, rgb(73, 73, 73), rgb(223, 223, 223));
  overflow: hidden;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  max-height: 80%;
}

.image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  filter: drop-shadow(10px 10px 10px black);
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.7);
  border: none;
  font-size: 2em;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 50%;
  display: none; /* Hide arrows by default */
}

.left-arrow {
  left: 20px;
}

.right-arrow {
  right: 20px;
}

@media screen and (min-width: 960px) {
  .arrow {
    display: block; /* Show arrows on larger screens */
  }
}

@media screen and (max-width: 960px) {
  .image-wrapper {
    margin-top: -40%;
    width: 110%;
    height: 200%;
    padding: 0;
  }

  .image {
    width: 110%;
    height: 50%;
    max-width: none;
    max-height: none;
    border-radius: 10px;
    filter: drop-shadow(10px 10px 10px black);
  }
}
