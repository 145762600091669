.home-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.header {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  font-size: 2em;
  font-weight: bold;
  z-index: 10;
}

/* Center header on mobile screens */
@media screen and (max-width: 960px) {
  .header {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.slideshow-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  object-fit: cover;
}

.slideshow-image.active {
  opacity: 1;
  z-index: 5;
}

.slideshow-image.prev,
.slideshow-image.next {
  opacity: 0;
  z-index: 1;
}
