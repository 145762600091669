/*Gallery Page*/
.gallery {
    background: linear-gradient(to bottom, rgb(73, 73, 73), rgb(223, 223, 223));
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.gallerybody {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.galleryentry {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 33%;
    padding: 20px;
    position: relative;
}

.galleryentry .caption {
    position: absolute;
    max-width: 100%;
    bottom: 0;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 1.2em;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding-left: 18%;
}

.galleryentry:hover .caption {
    opacity: 1;
}


.galleryentry2 {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 33%;
    padding: 20px;
    position: relative;
}

.galleryentry2 .caption {
    position: absolute;
    max-width: 100%;
    bottom: 0;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 1.2em;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding-left: 13%;
}

.galleryentry2:hover .caption {
    opacity: 1;
}


.galleryimage {
    width: 100%;
    max-width: 350px;
    max-height: 300px;
    border-radius: 10px;
    filter: drop-shadow(10px 10px 10px black);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.galleryimage2 {
    width: 150%;
    max-width: 350px;
    max-height: 260px;
    border-radius: 10px;
    filter: drop-shadow(10px 10px 10px black);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.galleryentry:hover .galleryimage {
    transform: translateY(-10px);
}

.galleryentry2:hover .galleryimage2 {
    transform: translateY(-10px);
}
/*gallery selection*/

@media screen and (max-width: 960px) {
    .gallery {
        background: linear-gradient(to bottom, rgb(73, 73, 73), rgb(223, 223, 223));
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
    }
    .galleryentry {
        width: 80%; /* Adjust size as needed */
        height: auto;
        margin-bottom: 20px;
    }

    .galleryentry2 {
        width: 80%; /* Adjust size as needed */
        padding-right: 27%;
        height: auto;
        margin-bottom: 20px;
    }

    .gallerybody {
        flex-direction: column;
        align-items: center;
    }
}
